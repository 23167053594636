import { createTheme } from '@shopify/restyle'

const palette = {
    purpleLight: '#8C6FF7',
    purplePrimary: '#5A31F4',
    purpleDark: '#3F22AB',

    greenLight: '#56DCBA',
    greenPrimary: '#0ECD9D',
    greenDark: '#0A906E',

    black: '#0B0B0B',
    white: '#F0F2F3',
}

const lightTheme = createTheme({
    colors: {
        mainBackground: palette.white,
        questionColor: palette.purplePrimary,
        headLineColor: palette.black
    },
    spacing: {
        s: 8,
        m: 16,
        l: 24,
        xl: 40,
    },
    breakpoints: {
        phone: 0,
        tablet: 768,
    },
    textVariants: {
        q: {
            fontSize: 25,
            lineHeight: 35,
            textAlign: 'center',
            color: 'questionColor',
            fontWeight: 'bold'
        },
        headline: {
            color: 'headLineColor'
        },
        defaults: {
            fontSize: 25,
        }
    },
})


export const darkTheme: Theme = {
    ...lightTheme,
    colors: {
        mainBackground: palette.black,
        questionColor: palette.purpleLight,
        headLineColor: palette.white
    },
}

export type Theme = typeof lightTheme;
export default lightTheme