
import { ThemeProvider } from '@shopify/restyle'
import React from 'react'
import { useColorScheme } from 'react-native'

import { Navigator } from './components/Navigator'
import lightTheme, { darkTheme } from './utils/theme'

export default function App() {
    
    const colorScheme = useColorScheme()

    return (
        <ThemeProvider theme={colorScheme == 'dark' ? darkTheme : lightTheme}>
            <Navigator />
        </ThemeProvider>
    )
}