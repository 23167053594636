export const questionList = [
    'Hvad er din mest kontroversielle holdning?',
    'Hvad er det største turn off?',
    'Hvad er dit største turn on?',
    'Er du forelsket på nuværende tidspunkt?',
    'Hvad skammer du dig mest over?',
    'Hvad er du mest usikker på i forhold til din krop?',
    'Hvilken slags menneske eller handling dømmer du mest?',
    'Hvad er det vildeste du har gjort sexuelt?',
    'Hvad er din største grænse?',
    'Hvad er din største familiehemmelighed?',
    'Hvad er du mest usikker på i forhold til din personlighed?',
    'Har din familie en familietragedie?',
    'Hvad er din største fortrydelse? ',
    'Hvad er du mest usikker på i et forhold?',
    'Hvad er du fucking god til?',
    'Hvilken type er du i sengen?',
    'Hvilken grænse har du med glæde overskredet?',
    'Er der en ting der er socialt uacceptabelt, som du syntes der skal være acceptabelt?',
    'Hvordan er dit forhold til din/dine tidligere sexuelle partner/partnere?',
    'Hvad er en ting du gemmer, du spiser?',
    'Er du mest til aften eller morgen sex?',
    'Hvad er din seksuelle hemmelighed?',
    'Hvad er du bedst til i sengen?',
    'Hvad er noget du gør, du aldrig snakker om?',
    'Hvad er en social uacceptabelt ting, du gør?',
    'Hvad er det værste du har gjort mod dig selv? ',
    'Hvilken grænse har du overskredet før du var klar?',
    'Hvor mange seksuelle partnere har du haft?',
    'Fortryder du nogle af dine partnere?',
    'Har du overvejet/haft en grad af et åbent forhold?',
    'Hvad er det du er mest stolt af ved dig selv?',
    'Hvad er en seksuel fantasi du har/haft?',
    'Har du en fetich? ',
    'Hvis du ikke havde frygt for noget, hvad ville du så?',
    'Har du sexlegetøj?',
    'Har du fantaseret om en kollega?',
    'Hvornår har du sidst onaneret?',
    'Hvornår havde du sidst sex?',
    'Ser du porno?',
    'Tænker du stadig på din ex?',
    'Hvis du skulle slå op med din kæreste, lige nu. Hvad ville grunden være?',
    'Hvilken slags porno ser du?',
    'Hvad er det vigtigste et politisk parti skal eller ikke gå ind for, for at få din stemme?',
    'Hvad tænder du mest på under sex?',
    'Hvad er den værste fetich din partner har/har haft?',
    'Hvor mange tinder dates har du været på?',
    'Har du knaldet din ex efter I blev ex? ',
    'Hvad stemmer du?',
    'Ser du dig selv som alfa af en gruppe?',
    'Har du forsøgt på selvskade?',
    'Hvis i går var din sidste dag på jorden, hvad ville du så fortryde du aldrig har gjort?',
    'Har du prøvet at få en abort eller fortrydelsespille eller har din partner?',
    'Hvad er en ting ved din opdragelse, du ikke vil gøre ved dit barn?',
    'Lever du det liv du håbede, da du var yngre, du vil leve? Og hvorfor?',
    'Hvis du skulle vælge mellem; et godt forhold med dårlig sex eller et dårligt med god sex hvad vil du vælge?',
    'Hvor er det mærkeligste sted du har onaneret?',
    'Har du en drøm du har givet op på? Og hvorfor?',
    'Hvad er din “moonshot”, en drøm der skræmmer dig?',
    'Hvis i går var din sidste dag på jorden, hvad ville du så fortryde du aldrig har fået sagt til en person?',
    'Hvad er en ting du syntes dine forældre mangler at forstå omkring dig?',
    'Hvilke følelser har du svært ved?',
    'Er du spirituel eller troende?',
    'Er hår et problem for dig?',
    'Har hjertesorger ændret dig?',
    'Hvornår følte du dig sidst ensom?',
    'Har et forhold ændret dig?',
    'Er det et problem for dig efter at have være gået ned på sin partner eller din partner har gået ned på dig at kysse derefter?',
    'Hvor ligger grænsen for utroskab for dig?',
    'Hvad er din holdning til tinder?',
    'Hvad er det værste mennesket har gjort mod sig selv?',
    'Tror du på kærlighed?',
    'Har du været utro?',
    'Hvordan vil du elskes?',
    'Hvornår føler du dig alene?',
    'Går du eller har du gået til psykolog?',
    'Har du nogensinde tænkt sexuelt om et familiemedlem?',
    'Hvad er det værste menneskeligheden har gjort?',
    'Hvorfor er du den bedsteven? Og er du det?',
    'Skammer du dig over at spise mere eller mindre end andre du spiser med?',
    'Toiletrullen vendt ud eller ind?',
    'Hvilken kvalitet har dit drømmeforhold?',
    'Hvad er det bedste du har gjort for dig selv?',
    'Er der en legitim grund til utroskab?',
    'Hvornår føler du dig mest levende?',
    'Top eller bund?',
    'Kan utroskab blive tilgivet?',
    'Hvad er en ting du ville ønske du aldrig havde fortalt nogen?',
    'Dom eller sub?',
    'Hvorfor skal man være i et forhold med dig?',
    'Hvad håber du, du vokser ud af?',
    'Hvornår er du fucking lækker?',
    'Hvad er den vigtigste lektie du har lært?',
    'Hvad får det værste frem i dig?',
    'Hvad får det bedste frem i dig?',
    'Nævn en ting du ikke vil stå ved du bruger penge på',
    'Hvornår føler du dig god nok?',
    'Anal?',
    'Hvem har du såret mest?',
    'Hvad er en handling du aldrig har tilgivet?',
    'Hvilken ting ville du ønske flere vidste om dig?',
    'Hvad er dit værste minde?',
    'Hvis du kunne sige en ting til en person der har såret dig, hvad ville du sige? Og til hvem?',
    'Hvilken ting syntes du folk ofte overreagerer omkring?',
    'Hvem ville du aldrig se igen, hvis du havde valget?',
    'Hvad har du brug for at høre lige nu?',
    'Hvor ligger grænsen for tilgivelse for dig?',
    'Hvad ville dit yngre jeg, være skuffet over vedrørende dit nuværende liv?',
    'Hvad har nogen sagt til dig, der fik dig til at se ned på personen efterfølgende?',
    'Har du lyst til at kysse nogen i dette rum?',
    'Nævn en ting du hader.',
    'Hvad er din holdning vedrørende aktiv dødshjælp?',
    'Hvad er den ting, der tager lang tid før du deler det med nogen?',
    'Er du introvert eller ekstrovert?',
    'Hvornår var den hårdeste periode af dit liv? Og hvad skete der?',
    'Kan man være venner med en person, med samme køn som din egen sexuelle præference?',
    'Er der liv efter døden?',
    'Mener du man er født ond eller god? Eller er det en lært kvalitet?',
    'Efter din mening er vi alene i universet?',
    'Er der en god grund til selvmord?',
    'Hvad gør dig speciel?',
    'Hvilken kvalitet ville du ønske du havde?',
    'Hvad er meningen med livet i din optik?',
    'Hvad er din holdning til dødstraf?',
    'Hvad har du på når du sover?',
    'Hvad er noget du aldrig har tilgivet dig selv for?',
    'Hvad skal man gøre for at få din respekt?',
    'Hvad er den sidste løgn du har fortalt?',
    'Har du/tænker du nogensinde på din ex mens du onanerer? ',
    'Hvis nogen skulle bede for dig, hvad skulle de bede?',
    'Vil du/har du haft en trekant og/eller “flerkant”?',
    'Hvor mange dates/gange man skal ses, før du har sex med personen?',
    'Kunne Jack være på døren med Rose?',
    'Kan man gå tilbage efter et seksuelt forhold?',
    'Tror du på giftemål?',
    'Hvad gør den perfekte partner i sengen?',
    'Hvordan får man det perfekte forhold?',
    'Hvordan vil du helst have kritik?',
    'Hvad gør den perfekte partner?',
    'Siger du til en partner, hvis et knald var dårligt?',
    'Hvordan vil du helst roses?',
    'Hvordan kan man score dig?',
    'Vil du kunne have et seriøst forhold med en partner med børn?',
    'Vil du/din partner få en abort eller fosterreduktion hvis i fik tvillinger/fler-linger',
    'Hvad vil du gøre hvis du/en pige blev gravid efter et one night stand?',
    'Vil du tage beslutningen om st slukke respiratoren for et tæt familiemedlem?',
    'Kunne du overveje aktiv dødshjælp?'
]